<template>
    <div>

        <div>
            <FlashSale></FlashSale>
        </div>
        <div>
            <LimitedTime :LimitedList="LimitedList"></LimitedTime>
        </div>


        <div class="shop-index" v-loading="loading">
            <div class="shop-left">
                <div class="shop-goods-search">
                    <el-input placeholder="search..." v-model="keyword" class="input-with-select" size="medium"
                        maxlength="50" clearable>
                        <el-button slot="append" icon="el-icon-search" @click="goGoodsList"></el-button>
                    </el-input>
                </div>
                <div class="shop-goods-cate" :class="{ border: categoryList.length }">
                    <div class="item" v-for="item in categoryList" :key="item.category_id">
                        <div class="menu-name"
                            @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id } })">
                            {{ item.category_name }}
                        </div>
                        <div class="submenu" v-for="cate in item.child_list" :key="cate.category_id"
                            @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id } })">
                            {{ cate.category_name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="shop-right">
                <div class="content">
                    <div class="tj" v-if="goodsList.length">
                        <h5>Recommended by our store</h5>
                    </div>
                    <div class="goods-info">
                        <div class="item" v-for="(item, index) in goodsList" :key="item.goods_id"
                            @click="$router.pushToTab({ path: `/sku-${item.sku_id}` })">
                            <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />

                            <div class="goods-name">{{ item.goods_name }}</div>
                            <div class="price-wrap">
                                <div class="price">${{ item.discount_price }}
                                </div>
                                <div class="market-price">${{ item.market_price }}</div>
                            </div>
                            <div class="promotion_type" v-if="item.promotion_type == 1">limited time discount</div>
                        </div>
                    </div>
                    <div class="pager">
                        <el-pagination background :pager-count="5" :total="total" :current-page.sync="currentPage"
                            :page-size.sync="pageSize" @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                    </div>
                    <!-- <div class="shop-banner">
                        <el-carousel height="160px" arrow="never">
                            <el-carousel-item v-for="item in adList" :key="item.adv_id">
                                <el-image :src="$img(item.adv_image)" fit="cover"
                                    @click="$router.pushToTab(item.adv_url.url)" />
                            </el-carousel-item>
                        </el-carousel>
                    </div> -->
                    <!-- <div class="mid-img">
                        <img class="img-wrap" src="../../assets/images/midImg.png" @error="imageError(index)" />
                    </div>
                    <div class="tj">
                        <h5>New Products</h5>
                    </div>
                    <div v-if="!newGoodsList.length">
                        <div class="top_posterBox">
                            <div class="newProducts"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/new_products.png" @error="imageError(index)" /></div>
                            <div class="imgBox">
                                <div class="products"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/famous.png" @error="imageError(index)" /></div>
                                <div class="products"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/bag.png" @error="imageError(index)" /></div>
                            </div>  
                            <div class="imgBox">
                                <div class="products"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/product.png" @error="imageError(index)" /></div>
                                <div class="products"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/fashion.png" @error="imageError(index)" /></div>
                            </div>
                        </div>
                        <div class="bottom_posterBox"><img @click="showNewProducts()" class="img-wrap" src="../../assets/images/fashionTop.png" @error="imageError(index)" /></div>
                    </div>
                    <div class="goods-info">
                        <div class="item" v-for="(item, index) in newGoodsList" :key="item.goods_id"
                            @click="$router.pushToTab({ path: `/sku-${item.sku_id}` })">
                            <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />

                            <div class="goods-name">{{ item.goods_name }}</div>
                            <div class="price-wrap">
                                <div class="price">
                                    <p>$</p>
                                    {{ item.discount_price }}
                                </div>
                                <div class="market-price">${{ item.market_price }}</div>
                            </div>
                            <div class="promotion_type" v-if="item.promotion_type == 1">limited time discount</div>
                        </div>
                    </div>
                    <div class="newPager">
                        <el-pagination background :pager-count="5" :total="newtotal" :current-page.sync="newCurrentPage"
                            :page-size.sync="newPageSize" @size-change="handleNewPageSizeChange"
                            @current-change="handleCurrentNewPageChange" hide-on-single-page></el-pagination>
                    </div>
                    <div class="tj">
                        <h5>Popular Products</h5>
                    </div>
                    <div class="goods-info">
                        <div class="item" v-for="(item, index) in saleGoodsList" :key="item.goods_id"
                            @click="$router.pushToTab({ path: `/sku-${item.sku_id}` })">
                            <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />

                            <div class="goods-name">{{ item.goods_name }}</div>
                            <div class="price-wrap">
                                <div class="price">
                                    <p>$</p>
                                    {{ item.discount_price }}
                                </div>
                                <div class="market-price">${{ item.market_price }}</div>
                            </div>
                            <div class="promotion_type" v-if="item.promotion_type == 1">limited time discount</div>
                        </div>
                    </div>
                    <div class="salePager">
                        <el-pagination background :pager-count="5" :total="saletotal" :current-page.sync="saleCurrentPage"
                            :page-size.sync="salePageSize" @size-change="handleSalePageSizeChange"
                            @current-change="handleCurrentSalePageChange" hide-on-single-page></el-pagination>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { shopInfo, isFollow, unFollow, follow, tree, getLimited } from "@/api/shop/index";
import { goodsSkuPage } from "@/api/goods/goods";
import { mapGetters } from "vuex";
import { adList } from "@/api/website";
import LimitedTime from '@/components/activity/LimitedTime.vue'
import FlashSale from '@/components/activity/FlashSale.vue'
export default {
    name: "shop_index",
    components: {
        LimitedTime,
        FlashSale
    },
    data: () => {
        return {
            id: 0,
            currentPage: 1,
            newCurrentPage: 1,
            saleCurrentPage: 1,
            pageSize: 12,
            newPageSize: 12,
            salePageSize: 4,
            shopInfo: {}, //店铺信息
            hasFollow: false, //是否关注
            searchContent: "",
            categoryList: [],
            goodsList: [],
            newGoodsList: [],
            saleGoodsList: [],
            total: 0,
            newtotal: 0,
            saletotal: 12,
            loading: true,
            keyword: "",
            adList: [],
            LimitedList: []
        };
    },
    computed: {
        ...mapGetters([
            "token",
            "defaultShopImage",
            "addonIsExit",
            "defaultGoodsImage"
        ])
    },
    created() {
        this.id = this.$route.path.replace("/mainshop", "4");
        this.getAdList();
        this.getShopInfo();
        this.getCategoryList();
        this.getGoodsList();
        // this.getSaleProducts();
        this.getfollow();
        this.getLimitedList();
    },
    watch: {
        $route(curr) {
            this.id = curr.params.pathMatch;
            this.getAdList();
            this.getShopInfo();
            this.getCategoryList();
            this.getGoodsList();
            // this.getSaleProducts();
            this.getfollow();
        }
    },
    methods: {
        /**
         * 获取限时商品列表
         */
        getLimitedList() {
            let data = {};
            getLimited(data).then(res => {
                if (res.code >= 0) {
                    this.LimitedList = res.data;
                }
            }).catch(err => err)
        },
        getAdList() {
            adList({ keyword: "NS_PC_SHOP_INDEX" })
                .then(res => {
                    this.adList = res.data.adv_list;
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url)
                            this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                    }
                })
                .catch(err => err);
        },
        //获取店铺信息
        getShopInfo() {
            shopInfo({ site_id: this.id })
                .then(res => {
                    if (res.code == 0) {
                        this.shopInfo = res.data;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取店铺商品分类列表
        getCategoryList() {
            tree({ site_id: this.id })
                .then(res => {
                    this.categoryList = res.data;
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取新品商品列表
        showNewProducts(){
            // this.loading = true;
            const params = {
                page: this.newCurrentPage,
                page_size: this.newPageSize,
                site_id: this.id,
                keyword: this.keyword,
                order: "new"
            };
            goodsSkuPage(params || {})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        let data = res.data;
                        this.newGoodsList = data.list;
                        this.newtotal = res.data.count;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取热销商品列表
        getSaleProducts(){
            // this.loading = true;
            const params = {
                page: this.saleCurrentPage,
                page_size: this.salePageSize,
                site_id: this.id,
                keyword: this.keyword,
                order: "sale_num"
            };
            goodsSkuPage(params || {})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        let data = res.data;
                        this.saleGoodsList = data.list;
                        // this.saletotal = res.data.count;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取店铺商品列表
        getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.id,
                keyword: this.keyword,
                sort: "desc"
            };
            goodsSkuPage(params || {})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        let data = res.data;
                        this.goodsList = data.list;
                        this.total = res.data.count;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
        },
        //获取店铺关注信息
        getfollow() {
            isFollow({
                site_id: this.id
            })
                .then(res => {
                    this.hasFollow = res.data;
                })
                .catch(err => {
                });
        },
        //关注与取消
        getIsFollow() {
            if (this.hasFollow) {
                unFollow({ site_id: this.id }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.hasFollow = !this.hasFollow;
                        this.$message({
                            message: "取消关注成功",
                            type: "success"
                        });
                    }
                }).catch(err => {
                    console.log(this.shopInfo, 'this.shopInfo')
                    if (err.message == 'token不存在') {
                        this.$router.push({ path: '/login', query: { redirect: "/mainshop" } });
                    } else {
                        this.$message.error(err.message);
                    }
                });
            } else {
                follow({ site_id: this.id }).then(res => {
                    if (res.code == 0) {
                        this.hasFollow = !this.hasFollow;
                        this.$message({
                            message: "关注成功",
                            type: "success"
                        });
                    }
                }).catch(err => {
                    if (err.message == 'token不存在') {
                        this.$router.push({ path: '/login', query: { redirect: "/mainshop" } });
                    } else {
                        this.$message.error(err.message);
                    }
                });
            }
        },
        goGoodsList() {
            this.$router.push({
                path: "/shop_list",
                query: { site_id: this.id, keyword: this.keyword }
            });
        },
        handlePageSizeChange(num) {
            this.pageSize = num;
            this.getGoodsList();
        },
        handleNewPageSizeChange(num) {
            this.newPageSize = num;
            this.showNewProducts();
        },
        handleSalePageSizeChange(num) {
            this.salePageSize = num;
            this.getSaleProducts();
        },
        handleCurrentPageChange(page) {
            this.currentPage = page;
            this.getGoodsList();
        },
        handleCurrentNewPageChange(page) {
            this.newCurrentPage = page;
            this.showNewProducts();
        },
        handleCurrentSalePageChange(page) {
            this.saleCurrentPage = page;
            this.getSaleProducts();
        },
        imageError(index) {
            this.goodsList[index].sku_image = this.defaultGoodsImage;
        }
    }
};
</script>
<style lang="scss" scoped>
.shop-index {
    padding-top: 10px;
    display: flex;
    background-color: #ffffff;

    .shop-left {
        width: 234px;

        .shop-wrap {
            width: 191px;
            float: right;
            border: 1px solid #eeeeee;
            padding: 0 21px;

            .head-wrap {
                text-align: center;
                padding: 10px 0;
                border-bottom: 1px solid #eeeeee;

                .img-wrap {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    display: inline-block;
                }

                .tag {
                    margin-left: 5px;
                }
            }

            .info-wrap {
                padding: 10px;
                color: #838383;

                b {
                    color: #383838;
                }

                border-bottom: 1px solid #eeeeee;
            }

            .other-info {
                padding: 10px;
                color: #838383;

                .item-info {
                    display: flex;

                    .item-name {
                        width: 75px;
                    }
                }

                .tell {
                    display: flex;

                    .tell-name {
                        width: 35px;
                    }
                }
            }

            .operation {
                border-top: 1px solid #eeeeee;
                padding: 10px 0;
                text-align: center;
            }
        }

        .shop-goods-search {
            .el-input {
                margin: 10px 0;
            }
        }

        .border {
            border: 1px solid #f1f1ff;
        }

        .shop-goods-cate {
            width: 100%;
            overflow: scroll;
            height: 900px;

            .menu-name {
                padding-left: 16px;
                background: #f8f8f8;
                font-size: $ns-font-size-base;
                height: 40px;
                cursor: pointer;
                color: #666666;
                display: flex;
                align-items: center;
            }

            .submenu {
                font-size: $ns-font-size-base;
                line-height: 2;
                border-top: 1px solid #f1f1f1;
                padding-left: 26px;
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 40px;
                background: #ffffff;
                color: #666666;
            }
        }
    }

    .shop-right {
        margin-left: 26px;
        width: 100%;

        .newProducts{
            width: 50%;
            text-align: center;
            cursor: pointer;
        }

        .shop-banner {
            width: 100%;
            margin: 30px 0;
            .el-image{
                height: 100%;
                width: 100%;
                border-radius: 10px;
            }
        }

        .mid-img .img-wrap{
            border-radius: 10px;
        }

        .top_posterBox{
            padding: 20px 0;
            display: flex;
            justify-content: space-between;
            .imgBox{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                .products{
                    width: 64%;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .bottom_posterBox{
            width: 100%;
            height: 160px;
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .tj {
            font-family: 'Courier New', Courier, monospace;
            font-family: Tiemann, serif;
            font-size: 30px;
            // font-size: 1.88125em;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: .025em;
            text-align: center;
            font-size: 30px;
            margin-top: 10px;
        }

        .goods-info {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;

            .item {
                width: calc((90% - 60px) / 4);
                margin: 10px 20px 0 0;
                border: 1px solid #eeeeee;
                padding: 10px;
                position: relative;
                // border-radius: 14px;

                &:nth-child(4n) {
                    margin-right: initial !important;
                }

                &:hover {
                    border: 1px solid $base-color;
                }

                .img-wrap {
                    width: 198px;
                    height: 198px;
                    cursor: pointer;
                }

                .goods-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;

                    &:hover {
                        color: $base-color;
                    }
                }

                .price-wrap {
                    display: flex;

                    .price {
                        display: flex;
                        color: $base-color;
                        font-size: $ns-font-size-lg;

                        p {
                            font-size: $ns-font-size-base;
                            display: flex;
                            align-items: flex-end;
                        }
                    }

                    .market-price {
                        color: #838383;
                        text-decoration: line-through;
                        margin-left: 15px;
                    }
                }

                .promotion_type {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #ffffff;
                    background: $base-color;
                    border-bottom-left-radius: 10px;
                    padding: 5px 8px;
                    line-height: 1;
                    font-size: $ns-font-size-sm;
                }
            }
        }
        .pager{
            margin: 15px 0;
        }
        .newPager{
            margin: 15px 0;
        }
        .salePager{
            margin: 15px 0;
        }

        .page {
            display: flex;
            justify-content: center;
            align-content: center;
            padding-top: 20px;
        }
    }
}
</style>
